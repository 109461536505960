import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import roLocale from 'date-fns/locale/ro';
import { MAP_LOCALE_TO_DATE_FNS_LOCALE } from '../constants';

export default function useSelectedLocale() {
  const [locale, setLocale] = useState(roLocale);
  const { i18n } = useTranslation();

  useEffect(() => {
    const currentLocale = MAP_LOCALE_TO_DATE_FNS_LOCALE[i18n.language];
    import(`date-fns/locale/${currentLocale}/index.js`)
      .then((localeModule) => {
        setLocale(localeModule);
      })
      .catch((error) => {
        console.error(
          `Failed to load date-fns locale for ${currentLocale}:`,
          error
        );
      });
  }, [i18n.language]);

  return locale;
}
