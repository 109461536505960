import { Address } from './locker.types';

export enum CustomerTypes {
  'courier_company' = 1,
  'partner',
}

export interface Customer {
  isDeleted?: boolean;
  customerId?: string;
  customerType: number;
  companyCUI: string;
  companyName: string;
  companyDescription?: string;
  companyWebSiteUrl?: string;
  contactPersonName: string;
  contactPersonPhone: string;
  contactPersonEmail: string;
  supportPhone: string;
  supportProgram?: string;
  supportEmail: string;
  loginEmail: string;
  companyAddress?: Address;
  createdAt?: string;
  customerExternalIdentifier?: string;
  customerName?: string;
  setPasswordRoute: string;
}

export interface CourierCode {
  customerId?: string;
  courierCode: string;
  lockerId?: string;
}

export enum CustomerFieldsNames {
  CustomerType = 'customerType',
  CompanyCUI = 'companyCUI',
  CompanyName = 'companyName',
  CompanyDescription = 'companyDescription',
  CompanyWebSiteUrl = 'companyWebSiteUrl',
  ContactPersonName = 'contactPersonName',
  ContactPersonPhone = 'contactPersonPhone',
  ContactPersonEmail = 'contactPersonEmail',
  SupportPhone = 'supportPhone',
  SupportProgram = 'supportProgram',
  SupportEmail = 'supportEmail',
  LoginEmail = 'loginEmail',
}
