/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { InputLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT } from '../../constants';

import { getDailyReportsMinDate, getYesterdayDate } from '../../utils';
import { DateType } from '../../types/general.types';
import useSelectedLocale from '../../utils/useSelectedLocale';

interface Props {
  onStartDateChange: (newValue: Date | null) => void;
  onEndDateChange: (newValue: Date | null) => void;
  startDate: DateType;
  endDate: DateType;
}

export default function DateIntervalFilter({
  onStartDateChange,
  onEndDateChange,
  startDate,
  endDate,
}: Props) {
  const { t } = useTranslation();
  const locale = useSelectedLocale();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          mb: 1,
        }}
      >
        <InputLabel sx={{ mb: 1 }}>{t('from')}</InputLabel>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
          <DesktopDatePicker
            inputFormat={DATE_FORMAT}
            value={startDate}
            maxDate={getYesterdayDate()}
            minDate={getDailyReportsMinDate()}
            onChange={onStartDateChange}
            renderInput={(params:any) => (
              <TextField
                {...params}
                sx={{ width: 200 }}
                inputProps={{ ...params.inputProps, readOnly: true }}
              />
            )}
          />
        </LocalizationProvider>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          mb: 1,
        }}
      >
        <InputLabel sx={{ mb: 1 }}>{t('until')}</InputLabel>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
          <DesktopDatePicker
            inputFormat={DATE_FORMAT}
            value={endDate}
            maxDate={getYesterdayDate()}
            minDate={getDailyReportsMinDate()}
            onChange={onEndDateChange}
            renderInput={(params:any) => (
              <TextField
                {...params}
                sx={{ width: 200 }}
                inputProps={{ ...params.inputProps, readOnly: true }}
              />
            )}
          />
        </LocalizationProvider>
      </Box>
    </>
  );
}
