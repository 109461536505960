import React from 'react';
import {
  DataGrid,
  GridColDef,
  GridFeatureMode,
  GridRowIdGetter,
  GridRowModel,
  GridRowParams,
  GridSelectionModel,
  GridSortModel,
  MuiEvent,
} from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import {
  DEFAULT_ITEMS_PER_PAGE,
  ITEMS_PER_PAGE,
  PAGINATION_INITIAL_PAGE_INDEX,
} from '../../constants';

export interface Props {
  columns: GridColDef[];
  rows: GridRowModel[];
  getId?: GridRowIdGetter;
  sortingMode?: GridFeatureMode;
  paginationMode?: GridFeatureMode;
  getRowClassName?: (params: GridRowParams) => string;
  onSortModelChange?: (params: GridSortModel) => void;
  onChangePageSize?: (pageSize: number) => void;
  onPageChange?: (page: number) => void;
  pageSize?: number;
  page?: number;
  rowCount?: number;
  sortModel?: GridSortModel;
  checkboxSelection?: boolean;
  disableSelectionOnClick?: boolean;
  isRowSelectable?: (params: GridRowParams) => boolean;
  onSelectionModelChange?: (model: GridSelectionModel) => void;
  selectionModel?: GridSelectionModel;
  onRowClick?: (
    params: GridRowParams,
    event: MuiEvent<React.MouseEvent<HTMLElement>>
  ) => void;
}

interface LabelRowsTypes {
  from: number;
  to: number;
  count: number;
}

export default function DataGridComponent({
  columns,
  rows,
  sortingMode = 'client',
  paginationMode = 'client',
  getId,
  getRowClassName,
  onSortModelChange,
  onChangePageSize,
  onPageChange,
  pageSize = DEFAULT_ITEMS_PER_PAGE,
  page = PAGINATION_INITIAL_PAGE_INDEX,
  rowCount,
  checkboxSelection = false,
  disableSelectionOnClick = true,
  isRowSelectable,
  onSelectionModelChange,
  selectionModel,
  onRowClick,
}: Props) {
  const { t } = useTranslation();
  const customLocalization = {
    noRowsLabel: t('grid.localization.noRowsLabel'),
    noResultsOverlayLabel: t('grid.localization.noResultsOverlayLabel'),
    footerRowSelected: (count: number) =>
      count > 1
        ? `${count.toLocaleString()} ${t('selected_rows')}`
        : `${count.toLocaleString()} ${t('selected_row')}`,
  };

  return (
    <div style={{ width: '100%' }}>
      <DataGrid
        sx={{
          '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
        }}
        componentsProps={{
          pagination: {
            getItemAriaLabel: (type: string) => {
              if (type === 'first') {
                return t('grid.localization.go_to_first_page');
              }
              if (type === 'last') {
                return t('grid.localization.go_to_last_page');
              }
              if (type === 'next') {
                return t('grid.localization.go_to_next_page');
              }
              return t('grid.localization.go_to_previous_page');
            },
            labelDisplayedRows: ({ from, to, count }: LabelRowsTypes) =>
              `${from}-${to} ${t('grid.localization.of')} ${count}`,
            labelRowsPerPage: t('grid.localization.labelRowsPerPage'),
          },
        }}
        localeText={customLocalization}
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        page={page}
        rowCount={rowCount}
        getRowId={getId}
        autoHeight
        onPageSizeChange={onChangePageSize}
        rowsPerPageOptions={ITEMS_PER_PAGE}
        pagination
        disableSelectionOnClick={disableSelectionOnClick}
        getRowClassName={getRowClassName}
        sortingMode={sortingMode}
        paginationMode={paginationMode}
        onSortModelChange={onSortModelChange}
        onPageChange={onPageChange}
        checkboxSelection={checkboxSelection}
        isRowSelectable={isRowSelectable}
        onSelectionModelChange={onSelectionModelChange}
        selectionModel={selectionModel}
        onRowClick={onRowClick}
        columnBuffer={Number.MAX_SAFE_INTEGER}
      />
    </div>
  );
}
