import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { ReactElement } from 'react';
import uniqid from 'uniqid';
import { AxiosError } from 'axios';
import { DateTime, Settings } from 'luxon';
import Fuse from 'fuse.js';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import i18n from '../config/i18n';
import {
  ABOVE_AVERAGE_FILL_COLOR,
  AVERAGE_FILL_COLOR,
  BELLOW_AVERAGE_FILL_COLOR,
  DEFAULT_LOCALE,
  DEFAULT_TIMEZONE,
  CARRIER_PASSWORD_ROUTE,
  DRAWERS_ID_PREFIX,
  errorMessages,
  MANAGEMENT_LOCALE_STOARGE_KEY,
  MANAGEMENT_TIMEZONE_STOARGE_KEY,
  MAP_DRAWER_STATE_TO_CLASS,
  MIN_WIDTH_ACTIONS,
  MAX_WIDTH_ACTIONS,
  PARTNER_PASSWORD_ROUTE,
  EXTERNAL_DOMAINS,
  MANAGEMENT_COUNTRY_CODE_STOARGE_KEY,
  COUNTRIES_CENTER_POINT_COORDINATES,
  COUNTRIES_COORDINATES_BOUNDS,
  COUNTRIES_MAPPING,
  RO_COUNTY_CODE,
  UNAUTH_MANAGEMENT_LOCALE_STOARGE_KEY,
  CONNECTED_LOCKER_TEMPLATES,
  AUTONOMOUS_LOCKER_TEMPLATES,
  AUTONOMOUS_LOCKER_TYPE_II,
  AUTONOMOUS_LOCKER_TYPE_I,
  COLUMN_ID_PREFIX,
} from '../constants';
import {
  CustomJwtPayload,
  MonthDTO,
  MonthlyCustomerTrendDTO,
  MonthlyCustomerTrendFrontDTO,
  MonthlyDrawersRentalTrendDTO,
  MonthlyDrawersRentalTrendFrontDTO,
  MonthlyLockerTrendsDTO,
  MonthlyLockerTrendsFrontDTO,
  MonthlyPackagesTrendDTO,
  MonthlyPackagesTrendFrontDTO,
  OptionType,
  QueryParams,
  SortDirection,
} from '../types/general.types';
import {
  Address,
  Drawer,
  DrawerState,
  DrawerStructure,
  DrawerToSave,
  DrawerType,
  DrawerTypeCount,
  IColumn,
  IDrawer,
  Locality,
  Locker,
  LockerColumnMap,
  LockerTemplate,
} from '../types/locker.types';
import { UserRoles } from '../types/user.types';
import { ACTIVITY_STATE, AuthorizationType } from '../types/activity.types';
import {
  PACKAGE_PAYMENT_TYPE,
  PackageState,
  Payment,
} from '../types/packages.types';
import { Customer, CustomerTypes } from '../types/customer.types';
import { getUser } from '../services/authService';
import { isConnectedLocker } from './lockers';

export const validateRole = (role: string): boolean => {
  return Boolean(UserRoles[+role]);
};

export const validateDates = (startDate: Date | null, endDate: Date | null) => {
  let isDateValid: boolean = false;
  if (startDate !== null && endDate !== null) {
    if (startDate.getTime() <= endDate.getTime()) {
      isDateValid = true;
    }
  }
  return isDateValid;
};

export const validateLatitude = (coordinate: string) => {
  const re =
    /^(\+|-)?(?:90(?:(?:\.0{1,8})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,8})?))$/;
  return re.test(coordinate);
};

export const validateLongitude = (coordinate: string) => {
  const re =
    /^(\+|-)?(?:180(?:(?:\.0{1,8})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,8})?))$/;
  return re.test(coordinate);
};

export const getActionsColumn = (
  renderCell: (params: GridRenderCellParams) => ReactElement
) => {
  return {
    field: 'actions',
    headerName: i18n.t('actions'),
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
    filterable: false,
    minWidth: MIN_WIDTH_ACTIONS,
    maxWidth: 500,
    renderCell,
  };
};

export const getDateFormat = (date: Date, serverFormat = false) => {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) {
    month = `0${month}`;
  }
  if (day.length < 2) {
    day = `0${day}`;
  }

  return serverFormat
    ? `${[year, month, day].join('-')}`
    : `${[day, month, year].join('/')}`;
};

export const getActivityState = (
  startDate: string,
  endDate: string
): string => {
  let state = '';
  const today = new Date();
  const start = new Date(startDate);
  const end = new Date(endDate);
  if (start < today && end < today) {
    state = ACTIVITY_STATE.completed;
  }

  if (end >= today) {
    state = ACTIVITY_STATE.in_progress;
  }

  if (start > today && end > today) {
    state = ACTIVITY_STATE.not_started;
  }

  return state;
};

export const getCustomColumn = (
  fieldName: string,
  headerName: string,
  minWidth: number
): GridColDef => {
  return {
    field: fieldName,
    headerName: i18n.t(headerName) as string,
    disableColumnMenu: true,
    flex: 1,
    minWidth,
  };
};

export const generateGridColumnsHeader = (
  availableHeaders: string[]
): GridColDef[] => {
  return availableHeaders.map((header) => {
    return {
      field: header,
      headerName: header,
      flex: 1,
    };
  });
};

export const generateGridColumnsHeaderRomanian = (
  availableHeaders: string[],
  availableDbNames: string[],
  minWidth: number,
  sortModel?: { field: string; sort: string }
): GridColDef[] => {
  return availableHeaders.map((header, idx) => {
    const headerName = i18n.t(header);
    return {
      field: availableDbNames[idx],
      headerName,
      flex: 1,
      disableColumnMenu: true,
      headerClassName:
        sortModel && sortModel.field === availableDbNames[idx]
          ? `data-grid-custom-${sortModel.sort}`
          : '',
      minWidth,
    };
  });
};

const isValidQueryParamValue = (
  value: string | number | boolean,
  filterOutZeroValue: boolean
) => {
  if (value === '') {
    return false;
  }
  if (filterOutZeroValue && value === 0) {
    return false;
  }
  return true;
};

export const getCleanParams = (
  queryParams: QueryParams,
  filterOutZeroValue = false
) => {
  return Object.fromEntries(
    Object.entries(queryParams)
      .filter(([, val]) => isValidQueryParamValue(val, filterOutZeroValue))
      .map(([_, val]) => {
        return [_, val.toString()];
      })
  );
};

// Generates the query string used in endpoints
// to add search, sort, pagination.
export const getQueryString = (
  queryParams: QueryParams,
  filterOutZeroValue = false
) => {
  const cleanQueryParams = getCleanParams(queryParams, filterOutZeroValue);
  return new URLSearchParams(cleanQueryParams).toString();
};

export const getDoorState = (isDrawerDoorOpen: boolean): string => {
  if (isDrawerDoorOpen) {
    return i18n.t('open');
  }
  return i18n.t('closed');
};

export const getLockerToSave = (rawLocker: Locker) => {
  const lockerToSave = { ...rawLocker };
  lockerToSave.lockerState = +rawLocker.lockerState;
  lockerToSave.longitude = +rawLocker.longitude;
  lockerToSave.latitude = +rawLocker.latitude;
  lockerToSave.lockerLocationType = +rawLocker.lockerLocationType;
  if (!lockerToSave.address?.addressId) {
    delete lockerToSave.address?.addressId;
  }
  if (!lockerToSave.targetVersionId) {
    delete lockerToSave.targetVersionId;
  }
  if (isConnectedLocker(lockerToSave.lockerType.toString())) {
    lockerToSave.hasPosSystem = true;
  } else {
    lockerToSave.hasPosSystem = false;
  }
  return lockerToSave;
};

export const downloadFile = (data: string, fileName: string) => {
  const url = window.URL.createObjectURL(
    new Blob([data], { type: 'application/octet-stream' })
  );
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', fileName);
  link.setAttribute('id', 'temporaryAnchor');
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.getElementById('temporaryAnchor')?.remove();
};

export const downloadZip = (data: string, fileName: string) => {
  const url = window.URL.createObjectURL(
    new Blob([data], { type: 'application/zip' })
  );
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', fileName);
  link.setAttribute('id', 'temporaryAnchor');
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.getElementById('temporaryAnchor')?.remove();
};

export enum LockerTabs {
  GeneralInformation = 'general_information',
  LockerCapabilities = 'hardware_capabilities',
  AssignDrawers = 'assign_drawers',
  LockerSettings = 'locker_settings',
  DrawersStructure = 'drawer_structure',
  DrawersState = 'drawers_state',
  PackagesHistory = 'packages_history',
  ActivitiesHistory = 'activities_history',
  DepositWithdrawalHistory = 'deposit_withdrawal_history',
  ConnectingInformation = 'connecting_information',
  LockerCommands = 'locker_commands',
  LockerCommandsLog = 'locker_commands_log',
  DriverCodes = 'driver_codes',
}

export const getPackageState = (packageState: string): string => {
  if (packageState === PackageState[4]) {
    return 'drop_off';
  }
  return 'pick_up';
};

export const getErrorMessage = (error: AxiosError, defaultErrorMsg: string) => {
  const { response } = error;

  if (response && Array.isArray(response.data)) {
    return errorMessages[response.data[0]]
      ? i18n.t(errorMessages[response.data[0]])
      : defaultErrorMsg;
  }
  return defaultErrorMsg;
};

export const doesColumnIdentifierExists = (
  columnIdentifier: string,
  columnList: IColumn[],
  isCentralColumn: boolean = false
) => {
  const columnIdentifierList: string[] = [];
  if (isCentralColumn) {
    columnList.forEach((item: IColumn) => {
      if (!item.position.startsWith('C')) {
        columnIdentifierList.push(item.board!);
      }
    });
  } else {
    columnList.forEach((item: IColumn) => {
      if (item.isSaved || item.position.startsWith('C')) {
        columnIdentifierList.push(item.board!);
      }
    });
  }
  return columnIdentifierList.includes(columnIdentifier);
};

export const getLastWeeksDate = () => {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
};

export const countAssignedDrawers = (drawerList: (Drawer | IDrawer)[]) => {
  return drawerList.reduce(
    (prev: number, current) => (current.customerId ? prev + 1 : prev),
    0
  );
};

export const isDrawerIdGenerated = (drawerId: string) => {
  return drawerId.startsWith(DRAWERS_ID_PREFIX);
};

export const hasDbDrawer = (drawers: IDrawer[] | IDrawer[]) => {
  return drawers.some((el) => !isDrawerIdGenerated(el.id));
};

export async function copyTextToClipboard(text: string) {
  if ('clipboard' in navigator) {
    return navigator.clipboard.writeText(text);
  }
  return document.execCommand('copy', true, text);
}

export const cutTextAtLength = (text: string, desiredLength: number) => {
  return text.length > desiredLength
    ? `${text.substring(0, desiredLength)} ...`
    : text;
};

// checks for additional ranges of latin characters
export const isAlphaName = (string: string) => {
  return /^[a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u024FF ,'-]+$/.test(string);
};
// checks for additional ranges of latin characters
export const isAlphaNumericName = (string: string) => {
  return /^[a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u024FF0-9 ,'-]+$/.test(
    string
  );
};
// checks for additional ranges of latin characters
export const isAlphaNumericLockerName = (string: string) => {
  return /^[a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u024FF0-9 -#]+$/.test(
    string
  );
};

export const formatBase64KeyToPEM = (key: string, type: string) => {
  const wrapped = key.replace(/(\S{64}(?!$))/g, '$1\r\n');
  return (
    // eslint-disable-next-line prefer-template
    '-----BEGIN ' +
    type.toUpperCase() +
    '-----\r\n' +
    wrapped +
    '\r\n-----END ' +
    type.toUpperCase() +
    '-----\r\n'
  );
};

export const getDrawerListWithBordPosition = (drawers: DrawerToSave[]) => {
  let nonCentralUnitCounter = 1;

  return drawers.map((el: DrawerToSave) => {
    const boardPosition = el.isCentralUnit ? -1 : nonCentralUnitCounter;
    if (!el.isCentralUnit) {
      nonCentralUnitCounter += 1;
    }
    return {
      ...el,
      boardPosition,
    };
  });
};

export const getAddress = (address: Address): string => {
  let completeAdress = '';
  if (address) {
    const { buildingNumber, floor, street, postCode } = address;
    if (street) {
      completeAdress += `${i18n.t('street_short')} ${street}`;
    }
    if (buildingNumber) {
      completeAdress += `, ${i18n.t('number_short')} ${buildingNumber}`;
    }
    if (floor) {
      completeAdress += `, ${i18n.t('floor')} ${floor}`;
    }
    if (postCode) {
      completeAdress += `, ${i18n.t('postal_code')} ${postCode}`;
    }
  }
  return completeAdress;
};

export const getYesterdayDate = () => {
  const now = new Date();
  now.setDate(now.getDate() - 1);
  return now;
};

export const getStartOfMonthyDate = () => {
  const now = new Date();
  now.setDate(1);
  return now;
};

export const getDonutChartFillColor = (value: number) => {
  if (value >= 90) {
    return ABOVE_AVERAGE_FILL_COLOR;
  }
  if (value > 50) {
    return AVERAGE_FILL_COLOR;
  }

  return BELLOW_AVERAGE_FILL_COLOR;
};

export const getStartDateUTC = (date: Date) => {
  date.setUTCHours(0, 0, 0, 0);
  return date.toISOString();
};

export const getEndDateUTC = (date: Date) => {
  date.setUTCHours(23, 59, 59, 999);
  return date.toISOString();
};

export const minutesToHoursAndMinutes = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return `${hours}h ${remainingMinutes}m`;
};

export const getPackageTimeInFlow = (timeInMinutes: number) => {
  return typeof timeInMinutes === 'number' && timeInMinutes > 0
    ? minutesToHoursAndMinutes(Math.round(timeInMinutes))
    : '-- ';
};

export const getPaymentAmount = (
  payments: Payment[],
  paymentType: PACKAGE_PAYMENT_TYPE
) => {
  const filteredPayments = payments.filter(
    (payment: Payment) => payment.type === paymentType
  );
  return filteredPayments[0] && filteredPayments[0].amount
    ? `${filteredPayments[0].amount} ron`
    : '';
};

// fuzzy search for arrays of objects
export const createFuseSearch = <T>(searchList: T[], keys: string[]) => {
  const options = {
    keys,
    threshold: 0.3,
  };
  return new Fuse(searchList, options);
};

export const filterMapLockerList = (lockers: Locker[]) => {
  const lockersNotInClusters: Locker[] = [];
  const lockersInClusters: Locker[] = [];
  lockers.forEach((el) => {
    if (Object.is(el.clusterId, null)) {
      lockersNotInClusters.push(el);
    } else {
      lockersInClusters.push(el);
    }
  });
  const lockersInClustersToShow = lockersInClusters.filter(
    (el, index, sourceArray) =>
      index === sourceArray.findIndex((item) => item.clusterId === el.clusterId)
  );

  return [...lockersNotInClusters, ...lockersInClustersToShow];
};

export const getDrawerTypeIdBySize = (
  size: number,
  drawerTypes: DrawerType[]
) => {
  const result = drawerTypes.find((drawerType) => drawerType.size === size);
  return result ? result.drawerTypeId : '';
};

export const getDrawerCountByDrawerTypeId = (
  drawerTypeId: string,
  drawerTypesCount: DrawerTypeCount[]
) => {
  const result = drawerTypesCount.find(
    (drawerTypeCounr) => drawerTypeCounr.drawerTypeId === drawerTypeId
  );
  return result ? result.count : '';
};

export const setSessionStorageKey = (key: string, value: string) => {
  sessionStorage.setItem(key, value);
};

export const removeSessionStorageKey = (key: string) => {
  sessionStorage.removeItem(key);
};

export const getSessionStorageItem = (key: string) => {
  return sessionStorage.getItem(key);
};

export const decodeJWTToken = (token: string) => {
  return jwt_decode<CustomJwtPayload>(token);
};

export const getAuthUserData = (token: string) => {
  const decodedToken = decodeJWTToken(token);
  return {
    access_token: token,
    expires_at: decodedToken.exp,
    token_type: 'Bearer',
    profile: {
      user_type: decodedToken.client_user_type || '',
      username: decodedToken.client_preferred_username || '',
    },
  };
};

// password validation rule: min 8 characters, at least one lower case letter,
// at least one upper case letter, at least one number and
// at least one non alpha numeric character
export const validatePassword = (password: string) => {
  const re =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-._!"`'#%&,:;<>=@{}~$()*/\\?[\]^|])[A-Za-z\d-._!"`'#%&,:;<>=@{}~$()*/\\?[\]^|]{8,}$/;
  return re.test(password);
};

export const delay = (ms: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const getDrawerStateClass = (state: number) => {
  return MAP_DRAWER_STATE_TO_CLASS[state]
    ? MAP_DRAWER_STATE_TO_CLASS[state]
    : '';
};

export const getObjectKeyByValue = (
  object: Record<string, number>,
  value: number
) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export const mapObjectToLabelValueArray = (
  obj: Record<string, number | string>,
  addEmptyOption: boolean
): OptionType[] => {
  let optionList = Object.keys(obj).map((key) => ({
    label: i18n.t(key),
    value: obj[key].toString(),
  }));
  if (addEmptyOption) {
    optionList = [{ value: '', label: '\u00A0' }, ...optionList];
  }

  return optionList;
};

export const isGetVideoMomentsReady = (locker: Locker) => {
  const { autonomousLockerInformation } = locker;

  return (
    autonomousLockerInformation?.hasGsm &&
    autonomousLockerInformation?.hasCamera
  );
};

const setDateTimeDefaults = () => {
  Settings.defaultLocale =
    (getSessionStorageItem(MANAGEMENT_LOCALE_STOARGE_KEY) as string) ||
    DEFAULT_LOCALE;
  Settings.defaultZone =
    (getSessionStorageItem(MANAGEMENT_TIMEZONE_STOARGE_KEY) as string) ||
    DEFAULT_TIMEZONE;
};

export const getDateTimeFormat = (date: string) => {
  setDateTimeDefaults();
  const dt = DateTime.fromISO(date);
  return dt.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
};

export const isoStringToDate = (date: string) => {
  setDateTimeDefaults();
  const dt = DateTime.fromISO(date);
  return dt.toLocaleString(DateTime.DATE_SHORT);
};

export const getUTCStartDateInMilis = (date: Date) => {
  setDateTimeDefaults();
  const dtStart = DateTime.fromJSDate(date);
  // set the start date at midnight 00:00:00
  const startDate = DateTime.local(
    dtStart.year,
    dtStart.month,
    dtStart.day,
    0,
    0,
    0
  );
  return startDate.toMillis();
};
export const getUTCEndDateInMilis = (date: Date) => {
  setDateTimeDefaults();
  const dtEnd = DateTime.fromJSDate(date);
  // set the end date at  23:59:59
  const endDate = DateTime.local(
    dtEnd.year,
    dtEnd.month,
    dtEnd.day,
    23,
    59,
    59
  );
  return endDate.toMillis();
};

export const getFullYear = (date: Date) => {
  setDateTimeDefaults();
  return DateTime.fromJSDate(date).toFormat('y');
};

export const getPaddedMonth = (date: Date) => {
  setDateTimeDefaults();
  return DateTime.fromJSDate(date).toFormat('LL');
};

export const getStartOfYearFromISOString = (isoString: string) => {
  setDateTimeDefaults();
  const date = DateTime.fromISO(isoString).toJSDate();
  const startOfYear = DateTime.fromJSDate(date).startOf('year').toJSDate();
  return startOfYear;
};

// endDate should be greater or equal with startDate
export const isValidActivityDate = (startDate: Date, endDate: Date) => {
  return getUTCEndDateInMilis(endDate) >= getUTCStartDateInMilis(startDate);
};

export const convertDateStringToDateShort = (dateString: string) => {
  const parsedDate = DateTime.fromFormat(dateString, 'yyyy-MM-dd');

  return parsedDate.toLocaleString(DateTime.DATE_SHORT);
};

export const getCSVReportName = (
  baseName: string,
  startDate: string,
  endDate: string
) => {
  return `${baseName}-${convertDateStringToDateShort(
    startDate
  )}-${convertDateStringToDateShort(endDate)}.csv`;
};

export const getLocale = () => {
  return getSessionStorageItem(MANAGEMENT_LOCALE_STOARGE_KEY) || DEFAULT_LOCALE;
};

export const getTimezone = () => {
  return (
    getSessionStorageItem(MANAGEMENT_TIMEZONE_STOARGE_KEY) || DEFAULT_TIMEZONE
  );
};

export const getCustomerSetPasswordRoute = (customer: Customer) => {
  return customer.customerType === CustomerTypes.courier_company
    ? CARRIER_PASSWORD_ROUTE
    : PARTNER_PASSWORD_ROUTE;
};

export const isExternalDomain = (urlString: string) => {
  try {
    const url = new URL(urlString);
    const domain = url.hostname;
    return EXTERNAL_DOMAINS.includes(domain);
  } catch (error) {
    return false;
  }
};

export const getCountryCentralPointCoordinates = () => {
  const countryCode = getSessionStorageItem(
    MANAGEMENT_COUNTRY_CODE_STOARGE_KEY
  )?.toLocaleLowerCase();
  return COUNTRIES_CENTER_POINT_COORDINATES[countryCode!];
};

export const getCountryCoordinatesBounds = () => {
  const countryCode = getSessionStorageItem(
    MANAGEMENT_COUNTRY_CODE_STOARGE_KEY
  )?.toLocaleLowerCase();
  return COUNTRIES_COORDINATES_BOUNDS[countryCode!];
};

export const getCountryName = () => {
  const countryCode = getSessionStorageItem(
    MANAGEMENT_COUNTRY_CODE_STOARGE_KEY
  )?.toLocaleLowerCase();
  return COUNTRIES_MAPPING[countryCode!];
};

export const getLocalityLabel = (locality: Locality) => {
  const countryCode = getSessionStorageItem(
    MANAGEMENT_COUNTRY_CODE_STOARGE_KEY
  )?.toLocaleLowerCase();
  if (countryCode === RO_COUNTY_CODE) {
    return locality.name;
  }
  return locality.adM2Name
    ? `${locality.name}, ${locality.adM2Name}`
    : locality.name;
};

export const setLocalStorageKey = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

export const getLocalStorageItem = (key: string) => {
  return localStorage.getItem(key);
};

export const getPortalLocale = () => {
  if (getUser()) {
    return (
      getSessionStorageItem(MANAGEMENT_LOCALE_STOARGE_KEY) || DEFAULT_LOCALE
    );
  }
  return (
    getLocalStorageItem(UNAUTH_MANAGEMENT_LOCALE_STOARGE_KEY) || DEFAULT_LOCALE
  );
};

export const isTechnicalActivity = (activityType: AuthorizationType) => {
  return AuthorizationType.technical_maintenance === activityType;
};

export const isDateBeforeNow = (
  dateString: string,
  dateFormat = 'dd.MM.yyyy, HH:mm:ss'
) => {
  const inputDate = DateTime.fromFormat(dateString, dateFormat);
  const currentTime = DateTime.utc();
  return inputDate < currentTime;
};

export const allowsSmartKeyOperations = (
  authorizationType: number,
  allowSmartKeyOperation: boolean
) => {
  if (isTechnicalActivity(authorizationType)) {
    return allowSmartKeyOperation ? i18n.t('yes') : i18n.t('no');
  }
  return i18n.t('not_available');
};

export const getLockerLabelByType = (type: number) => {
  const LOCKER_TYPES_TO_LABELS: Record<number, string> = {
    1: i18n.t('connected_locker'),
    2: i18n.t('autonomous_locker'),
  };
  return LOCKER_TYPES_TO_LABELS[type];
};

export const getCurrentMonthStartEndDates = () => {
  setDateTimeDefaults();
  const currentDate = DateTime.utc();

  const startDate = currentDate.startOf('month').toISO();
  const endDate = currentDate.toISO();

  return {
    startDate,
    endDate,
  };
};

export const getLastMonthsStartEndDates = (months: number) => {
  const currentDate = DateTime.utc();

  const startDate = currentDate
    .minus({ months: months - 1 })
    .startOf('month')
    .toISO();
  const endDate = currentDate.toISO();

  return {
    startDate,
    endDate,
  };
};

export const getLastNMonthsIncludingCurrent = (
  monthCount: number
): MonthDTO[] => {
  const currentDate = DateTime.utc();
  const months = [];

  for (let i = 0; i < monthCount; i += 1) {
    const currentMonthDate = currentDate.minus({ months: i });
    const monthName = currentMonthDate.toLocaleString({ month: 'short' });
    const { month, year } = currentMonthDate;

    months.push({ name: monthName, month, year });
  }

  return months;
};

export const getCustomerTrendGraphData = (
  monthList: MonthDTO[],
  customerTrendList: MonthlyCustomerTrendDTO[]
) => {
  const resultArray: MonthlyCustomerTrendFrontDTO[] = [];

  monthList.forEach((item: MonthDTO) => {
    const matchingItem = customerTrendList.find(
      (secondItem: MonthlyCustomerTrendDTO) =>
        secondItem.month === item.month && secondItem.year === item.year
    );

    if (matchingItem) {
      const mergedItem = { ...item, ...matchingItem };
      resultArray.push(mergedItem);
    } else {
      const newItem = {
        name: item.name,
        month: item.month,
        year: item.year,
        carrierCount: 0,
        partnerCount: 0,
      };
      resultArray.push(newItem);
    }
  });

  return resultArray;
};

export const getCurrentWeekIntervalUTC = () => {
  const today = DateTime.utc();
  const startOfWeek = today.startOf('week');

  return {
    startDate: startOfWeek.toISO(),
    endDate: today.toISO(),
  };
};

export const getLastWeekIntervalUTC = () => {
  const today = DateTime.utc();
  const startOfLastWeek = today.minus({ weeks: 1 }).startOf('week');
  const endOfLastWeek = today.minus({ weeks: 1 }).endOf('week');

  return {
    startDate: startOfLastWeek.toISO(),
    endDate: endOfLastWeek.toISO(),
  };
};

export const getLastMonthIntervalUTC = () => {
  const today = DateTime.utc();
  const startOfLastMonth = today.minus({ months: 1 }).startOf('month');
  const endOfLastMonth = today.minus({ months: 1 }).endOf('month');

  return {
    startDate: startOfLastMonth.toISO(),
    endDate: endOfLastMonth.toISO(),
  };
};

export const getStartOfMonthFromISO8601InUTC = (dateString: string) => {
  const parsedDate = DateTime.fromISO(dateString, { zone: 'utc' });
  const startOfMonth = parsedDate.startOf('month');

  return startOfMonth.toISO();
};

export const getShortMonthNameFromMonthNumber = (monthNumber: number) => {
  setDateTimeDefaults();
  const dateTime = DateTime.fromObject({ month: monthNumber });
  return dateTime.toLocaleString({ month: 'short' });
};

export const toRelativeDate = (isoDate: string) => {
  const dateTime = DateTime.fromISO(isoDate);
  const relativeDate = dateTime.toRelative();
  return relativeDate;
};

export const getStartAndEndDateForIntervalStartingYesterday = (
  numberOfDaysSinceYesterdy: number
) => {
  const today = DateTime.now().toUTC();

  const endDate = today
    .minus({ days: 1 })
    .set({ hour: 23, minute: 59, second: 59, millisecond: 999 });

  const startDate = endDate
    .minus({ days: numberOfDaysSinceYesterdy })
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

  return { startDate: startDate.toISO(), endDate: endDate.toISO() };
};

export const getYesterdayToMonthsAgoStartDateEndDate = (monthsAgo: number) => {
  const yesterday = DateTime.utc()
    .minus({ days: 1 })
    .set({ hour: 23, minute: 59, second: 59, millisecond: 999 });

  const startDate = yesterday
    .minus({ months: monthsAgo - 1 })
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

  return {
    startDate: startDate.toISO(),
    endDate: yesterday.toISO(),
  };
};

export const timestampToShortMonthYear = (timestamp: number) => {
  setDateTimeDefaults();
  return DateTime.fromMillis(timestamp).toLocaleString({
    month: 'short',
    year: '2-digit',
  });
};

export const timestampToDate = (timestamp: number) => {
  setDateTimeDefaults();
  return DateTime.fromMillis(timestamp).toLocaleString({
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
};

export const getDrawersRentalTrendChartData = (
  monthList: MonthDTO[],
  drawersRentalTrendList: MonthlyDrawersRentalTrendDTO[]
) => {
  const resultArray: MonthlyDrawersRentalTrendFrontDTO[] = [];

  monthList.forEach((item: MonthDTO) => {
    const matchingItem = drawersRentalTrendList.find(
      (secondItem: MonthlyDrawersRentalTrendDTO) =>
        secondItem.month === item.month && secondItem.year === item.year
    );

    if (matchingItem) {
      const mergedItem = { ...item, ...matchingItem };
      resultArray.push(mergedItem);
    } else {
      const newItem = {
        name: item.name,
        month: item.month,
        year: item.year,
        count: 0,
      };
      resultArray.push(newItem);
    }
  });

  return resultArray;
};

export const formatNumberToCompactForm = (number: number) => {
  const formattedNumber = new Intl.NumberFormat(getLocale(), {
    notation: 'compact',
  }).format(number);
  return formattedNumber;
};

export const convertTimestampToUTCDateTime = (timestamp: number) => {
  setDateTimeDefaults();
  return DateTime.fromMillis(timestamp, { zone: 'utc' }).toISO();
};

export const getNextNMonthsIncludingCurrent = (
  monthCount: number,
  startDateISO: string
): MonthDTO[] => {
  const startDate = DateTime.fromISO(startDateISO).setZone('utc');
  const months = [];

  for (let i = 0; i < monthCount; i += 1) {
    const currentMonthDate = startDate.plus({ months: i });
    const monthName = currentMonthDate.toLocaleString({ month: 'short' });
    const { month, year } = currentMonthDate;

    months.push({ name: monthName, month, year });
  }

  return months;
};

export const getPackagesTrendChartData = (
  monthList: MonthDTO[],
  packagesDataList: MonthlyPackagesTrendDTO[]
) => {
  const resultArray: MonthlyPackagesTrendFrontDTO[] = [];

  monthList.forEach((item: MonthDTO) => {
    const matchingItem = packagesDataList.find(
      (secondItem: MonthlyPackagesTrendDTO) =>
        secondItem.month === item.month && secondItem.year === item.year
    );

    if (matchingItem) {
      const mergedItem = { ...item, ...matchingItem };
      resultArray.push(mergedItem);
    } else {
      const newItem = {
        name: item.name,
        month: item.month,
        year: item.year,
        finishedPackages: 0,
        canceledPackages: 0,
      };
      resultArray.push(newItem);
    }
  });

  return resultArray;
};

export const getStartDateAndEndDateForMaxOneYearInterval = (
  startDateISO: string
) => {
  const startDateTime = DateTime.fromISO(startDateISO, { zone: 'utc' });
  const endDateTime = DateTime.now().setZone('utc');

  const differenceInDays = endDateTime
    .diff(startDateTime, 'days')
    .toObject().days;

  if (differenceInDays && differenceInDays > 365) {
    const adjustedStartDate = startDateTime.plus({
      days: differenceInDays - 365,
    });
    return {
      startDate: adjustedStartDate.toISO(),
      endDate: endDateTime.toISO(),
    };
  }

  return { startDate: startDateTime.toISO(), endDate: endDateTime.toISO() };
};

export const getLockersTrendChartData = (
  monthList: MonthDTO[],
  lockersDataList: MonthlyLockerTrendsDTO[]
) => {
  const resultArray: MonthlyLockerTrendsFrontDTO[] = [];

  monthList.forEach((item: MonthDTO) => {
    const matchingItem = lockersDataList.find(
      (secondItem: MonthlyLockerTrendsDTO) =>
        secondItem.month === item.month && secondItem.year === item.year
    );

    if (matchingItem) {
      const mergedItem = { ...item, ...matchingItem };
      resultArray.push(mergedItem);
    } else {
      const newItem = {
        name: item.name,
        month: item.month,
        year: item.year,
        autonomousLocker: { count: 0 },
        classicLocker: { count: 0 },
      };
      resultArray.push(newItem);
    }
  });

  return resultArray;
};

export const getDayListStartingYesterday = (numberOfDays: number) => {
  const today = DateTime.utc();
  const days = [];

  for (let i = 1; i <= numberOfDays; i += 1) {
    const day = today.minus({ days: i });
    const formattedDate = day.toFormat('yyyy-LL-dd');
    const dayObject = {
      day: day.day,
      year: day.year,
      month: day.month,
      date: formattedDate,
    };
    days.push(dayObject);
  }

  return days;
};

export const getDayListStartingDaysAgo = (numberOfDays: number) => {
  const today = DateTime.utc();
  const days = [];

  for (let i = numberOfDays; i > 0; i -= 1) {
    const day = today.minus({ days: i });
    const formattedDate = day.toFormat('yyyy-LL-dd');
    const dayObject = {
      day: day.day,
      year: day.year,
      month: day.month,
      date: formattedDate,
    };
    days.push(dayObject);
  }

  return days;
};

export const getFirstDayOfCurrentMonthInISOAtMidnight = () => {
  const currentDateInUTC = DateTime.now().toUTC();
  const firstDayOfCurrentMonth = currentDateInUTC.startOf('month');
  const startOfDayFirstDayOfCurrentMonth =
    firstDayOfCurrentMonth.startOf('day');
  const isoDate = startOfDayFirstDayOfCurrentMonth.toISO();

  return isoDate;
};

export const getDayListWithinInterval = (
  startDateISO: string,
  numberOfMonths: number
) => {
  const startDate = DateTime.fromISO(startDateISO).startOf('day');
  const endDate = startDate.plus({ months: numberOfMonths }).endOf('month');
  if (endDate.toMillis() < startDate.toMillis()) {
    throw new Error('End date must be greater the start date.');
  }

  const days = [];

  let currentDate = startDate;
  while (currentDate <= endDate) {
    const formattedDate = currentDate.toFormat('yyyy-LL-dd');
    const dayObject = {
      day: currentDate.day,
      year: currentDate.year,
      month: currentDate.month,
      date: formattedDate,
    };
    days.push(dayObject);
    currentDate = currentDate.plus({ days: 1 });
  }

  return days;
};

// dateString should be of form yyyy-LL-dd -> 2023-10-24
export const dateToMillisUTC = (dateString: string) => {
  const date = DateTime.fromFormat(dateString, 'yyyy-LL-dd', { zone: 'utc' });
  if (!date.isValid) {
    throw new Error(
      'Invalid date format. The expected format is "yyyy-LL-dd".'
    );
  }
  return date.toMillis();
};

// returns a interval between startDateISO and max the number of month since startDateISO
export const calculateBrushChartStartDateAndEndDate = (
  startDateISO: string,
  months: number
) => {
  const startDate = DateTime.fromISO(startDateISO, { zone: 'utc' }).startOf(
    'day'
  );

  const endDate = DateTime.now()
    .minus({ days: 1 })
    .setZone('utc')
    .startOf('day');

  const maxEndDate = startDate.plus({ months }).startOf('day');
  const daysUntilIntervalEnd = maxEndDate.diff(startDate, 'days').days;
  const differenceInDays = endDate.diff(startDate, 'days').toObject().days;

  if (differenceInDays && differenceInDays > daysUntilIntervalEnd) {
    const adjustedStartDate = startDate.plus({
      days: differenceInDays - daysUntilIntervalEnd,
    });
    return {
      startDate: adjustedStartDate.startOf('day').toISO(),
      endDate: endDate.endOf('day').toISO(),
    };
  }

  return {
    startDate: startDate.startOf('day').toISO(),
    endDate: endDate.endOf('day').toISO(),
  };
};
// dateString should be in the 2023-10-13(yyyy-MM-dd) format
export const convertToUTCAtMidnight = (dateString: string) => {
  const inputDate = DateTime.fromFormat(dateString, 'yyyy-MM-dd', {
    zone: 'utc',
  });

  const utcDate = inputDate
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .setZone('UTC');

  return utcDate.toISO();
};
// dateString should be in the 2023-10-13(yyyy-MM-dd) format
export const convertToUTCEndOfDay = (dateString: string) => {
  const inputDate = DateTime.fromFormat(dateString, 'yyyy-MM-dd', {
    zone: 'utc',
  });

  const utcDate = inputDate
    .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
    .setZone('UTC');

  return utcDate.toISO();
};

// the startDate and endDate should be in the 2023-10-13(yyyy-MM-dd) format
export const getStartOfTheDayEndOfTheDayUTC = (
  startDate: string,
  endDate: string
) => {
  return {
    startDate: convertToUTCAtMidnight(startDate),
    endDate: convertToUTCEndOfDay(endDate),
  };
};

export const jsDateToYYYYMMDDFormat = (date: Date) => {
  return date.toISOString().slice(0, 10);
};

export const getLockerCentralUnitDrawers = (
  lockerCentralUnitType: string,
  templateList: LockerTemplate[]
) => {
  return templateList.find(
    (item: LockerTemplate) => item.type === lockerCentralUnitType
  );
};

export const generateDrawer = (
  size: number,
  columnId: string,
  isCentralUnit: boolean = false
) => {
  const drawer: IDrawer = {
    size,
    id: uniqid(DRAWERS_ID_PREFIX),
    columnId,
  };
  if (isCentralUnit) {
    drawer.isCentralUnit = true;
  }

  return drawer;
};

export const getColumnDrawers = (
  drawersStructure: DrawerStructure[],
  columnId: string
) => {
  const drawers = [];
  for (let i = 0; i < drawersStructure.length; i += 1) {
    for (let j = 0; j < drawersStructure[i].quantity; j += 1) {
      drawers.push(
        generateDrawer(
          drawersStructure[i].size,
          columnId,
          drawersStructure[i].isCentralUnit
        )
      );
    }
  }
  return drawers;
};

export const getLockerTemplateList = (lockerType: string) => {
  if (isConnectedLocker(lockerType)) {
    return CONNECTED_LOCKER_TEMPLATES;
  }
  return AUTONOMOUS_LOCKER_TEMPLATES;
};

export const sortAutonomousColumnsByColumn = (
  autonomousColumnList: IDrawer[][]
): IDrawer[][] => {
  const sortedData = [...autonomousColumnList].sort(
    (arr1: IDrawer[], arr2: IDrawer[]) => {
      const columnOne = arr1[0].columnId;
      const columnTwo = arr2[0].columnId;
      return columnOne!.localeCompare(columnTwo!);
    }
  );

  return sortedData;
};

export const sortArrayOfObjByProperty = (
  array: any[],
  property: string,
  direction: SortDirection = 'asc'
) => {
  return [...array].sort((a, b) => {
    if (a[property] < b[property]) {
      return direction === 'asc' ? -1 : 1;
    }
    if (a[property] > b[property]) {
      return direction === 'asc' ? 1 : -1;
    }
    return 0;
  });
};

export const getLockerColumns = (drawers: Drawer[]): LockerColumnMap => {
  return drawers.reduce((result, drawer) => {
    const { column } = drawer;

    if (!result[column]) {
      // eslint-disable-next-line no-param-reassign
      result[column] = [];
    }

    result[column].push(drawer);

    return result;
  }, {} as LockerColumnMap);
};

export const getSortedLockerColumns = (columns: LockerColumnMap) => {
  const lockerColumnList = Object.entries(columns).map(([column, drawers]) => ({
    column,
    drawers,
  }));
  const leftColumns = lockerColumnList.filter((lockerColumn) =>
    lockerColumn.column.startsWith('L')
  );
  const rightColumns = lockerColumnList.filter((lockerColumn) =>
    lockerColumn.column.startsWith('R')
  );
  const centralColumns = lockerColumnList.filter((lockerColumn) =>
    lockerColumn.column.startsWith('C')
  );

  const sortedLeftColumns = sortArrayOfObjByProperty(
    leftColumns,
    'column',
    'desc'
  );
  const sortedRightColumns = sortArrayOfObjByProperty(rightColumns, 'column');
  const sortedCentralColumns = sortArrayOfObjByProperty(
    centralColumns,
    'column'
  );
  return [...sortedLeftColumns, ...sortedCentralColumns, ...sortedRightColumns];
};

export const getAutonomousLockerStructureType = (dbDrawers: Drawer[]) => {
  const uniqueColumns = new Set();

  dbDrawers.forEach((obj) => {
    uniqueColumns.add(obj.column);
  });
  console.log('uniqueColumns',uniqueColumns)
  return uniqueColumns.size > 2
    ? AUTONOMOUS_LOCKER_TYPE_II
    : AUTONOMOUS_LOCKER_TYPE_I;
};

export const getAutonomousTemplateByType = (templateType: string) => {
  return AUTONOMOUS_LOCKER_TEMPLATES.find(
    (template) => template.type === templateType
  );
};

export const hasAllDrawersInactive = (drawersList: Drawer[]) => {
  return drawersList.every(
    (drawer) => drawer.drawerState === DrawerState.inactive
  );
};

export const getDrawerDimensions = (drawer: DrawerType, showLabel = true) => {
  const { width, height, length, label } = drawer;
  if (!showLabel) {
    return `(${i18n.t('width_short')} ${width}cm x ${i18n.t(
      'height_short'
    )} ${height}cm x ${i18n.t('length_short')} ${length}cm)`;
  }
  return `${label} (${i18n.t('width_short')} ${width}cm x ${i18n.t(
    'height_short'
  )} ${height}cm x ${i18n.t('length_short')} ${length}cm)`;
};

export const getDrawerTypeBySize = (
  drawerTypeList: DrawerType[],
  size: number
) => {
  return drawerTypeList.find((drawerType) => drawerType.size === size);
};

export const sortDrawerTypesBySize = (drawerTypeList: DrawerType[]) => {
  return drawerTypeList.sort((a: DrawerType, b: DrawerType) => a.size - b.size);
};

export const getDrawersToAssignCount = (
  drawerTypeList: DrawerType[],
  drawerTypeInputValues: Record<string, number>
) => {
  const drawerTypesCount: DrawerTypeCount[] = [];

  Object.entries(drawerTypeInputValues).forEach(([drawerType, count]) => {
    if (count > 0) {
      const drawerTypeId = getDrawerTypeIdBySize(
        parseInt(drawerType, 10),
        drawerTypeList
      );

      drawerTypesCount.push({
        count,
        drawerTypeId,
      });
    }
  });

  return drawerTypesCount;
};

export const sortDrawerTypesByVolume = (drawerTypeList: DrawerType[]) => {
  const drawersWithVolume = drawerTypeList.map((drawerType) => ({
    ...drawerType,
    volume: drawerType.width * drawerType.length * drawerType.height,
  }));

  const sortedDrawers = drawersWithVolume.sort((a, b) => a.volume - b.volume);

  return sortedDrawers;
};

export const getSummaryOfDrawersSelected = (
  drawerTypesList: DrawerType[],
  selectedDrawerTypes: Record<string, number>
) => {
  const drawerCounts = new Map();

  drawerTypesList.forEach((drawer) => {
    const drawerType = drawer.label;
    const drawerSize = drawer.size;

    if (selectedDrawerTypes[drawerSize]) {
      if (!drawerCounts.has(drawerType)) {
        drawerCounts.set(drawerType, selectedDrawerTypes[drawerSize]);
      } else {
        drawerCounts.set(
          drawerType,
          drawerCounts.get(drawerType) + selectedDrawerTypes[drawerSize]
        );
      }
    }
  });

  let summary = `${i18n.t('selected_drawer_types')}:`;
  drawerCounts.forEach((count, type) => {
    summary += ` ${i18n.t('type').toLowerCase()} ${type}: ${count},`;
  });

  return summary.slice(0, -1);
};

export const removeDuplicatesLockersByLockerCode = (lockerList: Locker[]) => {
  const seenLockerCodes = new Set();
  return lockerList.filter(
    (obj) =>
      !seenLockerCodes.has(obj.lockerCode) &&
      seenLockerCodes.add(obj.lockerCode)
  );
};

export const addBoardPosition = (drawers: DrawerToSave[]) => {
  const boardPositionCounter: { [key: string]: number } = {};
  return drawers.map((drawer) => {
    if (drawer.isDeleted) {
      return drawer;
    }
    const boardValue = drawer.board;
    const isCentralUnit = drawer.isCentralUnit === true;

    if (!isCentralUnit) {
      boardPositionCounter[boardValue] =
        (boardPositionCounter[boardValue] || 0) + 1;
    }
    const boardPosition =
      isCentralUnit === true ? -1 : boardPositionCounter[boardValue];

    return {
      ...drawer,
      boardPosition,
    };
  });
};

export const isGeneratedColumnId = (columnId: string) => {
  return columnId.startsWith(COLUMN_ID_PREFIX);
};

export const getDrawer = (
  size: number,
  columnId: string,
  columnPosition: string,
  boardIdentifier: string,
  isCentralUnit: boolean = false
) => {
  const drawer: IDrawer = {
    size,
    id: uniqid(DRAWERS_ID_PREFIX),
    columnId,
    columnPosition,
    board: boardIdentifier,
  };
  if (isCentralUnit) {
    drawer.isCentralUnit = true;
  }

  return drawer;
};

export const generateColumnDrawers = (
  drawersStructure: DrawerStructure[],
  columnId: string,
  columnPosition: string,
  boardIdentifier: string
) => {
  const drawers = [];
  for (let i = 0; i < drawersStructure.length; i += 1) {
    for (let j = 0; j < drawersStructure[i].quantity; j += 1) {
      drawers.push(
        getDrawer(
          drawersStructure[i].size,
          columnId,
          columnPosition,
          boardIdentifier,
          drawersStructure[i].isCentralUnit
        )
      );
    }
  }
  return drawers;
};

export const hasNoAssignedDrawers = (drawersList: Drawer[]) => {
  return drawersList.every(
    (drawer) => Object.is(drawer.customerId, null) === true
  );
};

export const formatNumberToDecimalOrInteger = (num: number) => {
  if (Number.isInteger(num)) {
    return num;
  }
  return num.toFixed(2);
};

// 90 days ago starting yesterday
export const getDailyReportsMinDate = () => {
  setDateTimeDefaults();
  const yesterday = DateTime.utc().minus({ days: 1 });
  const ninetyDaysAgo = yesterday.minus({ days: 90 });
  return ninetyDaysAgo.toJSDate();
};
