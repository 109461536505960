import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import UserActivities from '../users/UserActivities';
import LoadingLineComponent from './LoadingLineComponent';
import CustomerAddCourierCode from '../customers/CustomerAddCourierCode';
import CCTVSearch from '../cctv/CCTVSearch';

const UserList = React.lazy(() => import('../users/UserList'));
const CreateUser = React.lazy(() => import('../users/CreateUser'));
const EditUser = React.lazy(() => import('../users/EditUser'));
const Layout = React.lazy(() => import('./Layout'));
const LockerList = React.lazy(() => import('../lockere/LockerList'));
const AddLocker = React.lazy(() => import('../lockere/AddLocker'));
const Locker = React.lazy(() => import('../lockere/Locker'));
const LockerLogs = React.lazy(() => import('../lockere/LockerLogs'));
const Events = React.lazy(() => import('../events/Events'));
const Activities = React.lazy(() => import('../activities/Activities'));
const CreateActivity = React.lazy(() => import('../activities/CreateActivity'));
const EditActivity = React.lazy(() => import('../activities/EditActivity'));
const ActionLogs = React.lazy(() => import('../activities/ActionLogs'));
const Configuration = React.lazy(
  () => import('../configuration/Configuration')
);
const PackageHistory = React.lazy(
  () => import('../packageHistory/PackageHistory')
);
const PackageDetails = React.lazy(
  () => import('../packageHistory/PackageDetails')
);
const VideoList = React.lazy(() => import('./VideoList'));
const CustomerList = React.lazy(() => import('../customers/CustomerList'));
const AddCustomer = React.lazy(() => import('../customers/AddCustomer'));
const EditCustomer = React.lazy(() => import('../customers/EditCustomer'));
const CustomerCourierCodes = React.lazy(() => import('../customers/CustomerCourierCodes'));
const CustomerLockers = React.lazy(
  () => import('../customers/CustomerLockers')
);
const CustomerBilling = React.lazy(
  () => import('../customers/CustomerBilling')
);
const ClusterList = React.lazy(() => import('../clusters/ClusterList'));
const CreateCluster = React.lazy(() => import('../clusters/CreateCluster'));
const EditCluster = React.lazy(() => import('../clusters/EditCluster'));
const AssignClusterToCustomer = React.lazy(
  () => import('../clusters/AssignClusterToCustomer')
);
const UnassignClusterFromCustomer = React.lazy(
  () => import('../clusters/UnassignClusterFromCustomer')
);

const Reports = React.lazy(() => import('../reports/Reports'));
const FleetLoadReport = React.lazy(() => import('../reports/FleetLoadReport'));
const LockerLoadReport = React.lazy(
  () => import('../reports/LockerLoadReport')
);

const ClustersLoadReport = React.lazy(
  () => import('../reports/ClustersLoadReport')
);
const ClusterLoadReport = React.lazy(
  () => import('../reports/ClusterLoadReport')
);
const FleetUptimeReport = React.lazy(
  () => import('../reports/FleetUptimeReport')
);
const PackageTimeAtLockerReport = React.lazy(
  () => import('../reports/PackageTimeAtLockerReport')
);
const DrawersRentalReport = React.lazy(
  () => import('../reports/DrawersRentalReport')
);

const MyAccount = React.lazy(() => import('../account/MyAccount'));
const Dashboard = React.lazy(() => import('../dashboard/Dashboard'));
const DashboardEvents = React.lazy(
  () => import('../dashboard/DashboardEvents')
);
const DashboardLockerIncidents = React.lazy(
  () => import('../dashboard/DashboardLockerIncidents')
);
const DashboardDrawerIncidents = React.lazy(
  () => import('../dashboard/DashboardDrawerIncidents')
);
const DashboardPackageIncidents = React.lazy(
  () => import('../dashboard/DashboardPackageIncidents')
);
const DashboardCustomersDetails = React.lazy(
  () => import('../dashboard/DashboardCustomersDetails')
);
const DashboardFleetLoadTrends = React.lazy(
  () => import('../dashboard/DashboardFleetLoadTrends')
);
const DashboardFleetUptimeTrends = React.lazy(
  () => import('../dashboard/DashboardFleetUptimeTrends')
);
const DashboardClustersLoadTrends = React.lazy(
  () => import('../dashboard/DashboardClustersLoadTrends')
);

const LowBatteryLockerList = React.lazy(
  () => import('../dashboard/LowBatteryLockerList')
);

const PageNotFound = React.lazy(() => import('./PageNotFound'));

export default function PortalRoutes() {
  return (
    <div>
      <React.Suspense fallback={<LoadingLineComponent />}>
        <Routes>
          <Route path='/' element={<Layout />}>
            <Route index element={<Navigate replace to='/dashboard' />} />
            <Route path='users' element={<UserList />} />
            <Route path='users/:id' element={<EditUser />} />
            <Route
              path='users/:userId/activities'
              element={<UserActivities />}
            />
            <Route
              path='users/:userId/activities/:id/view'
              element={<ActionLogs />}
            />
            <Route path='users/create-user' element={<CreateUser />} />
            <Route path='lockers' element={<LockerList />} />
            <Route path='lockers/add-locker' element={<AddLocker />} />
            <Route path='lockers/:lockerCode/*' element={<Locker />} />
            <Route path='lockers/:lockerCode/logs' element={<LockerLogs />} />
            <Route path='clusters' element={<ClusterList />} />
            <Route path='clusters/create-cluster' element={<CreateCluster />} />
            <Route path='clusters/:clusterId' element={<EditCluster />} />
            <Route
              path='clusters/:clusterId/assign-to-customer'
              element={<AssignClusterToCustomer />}
            />
            <Route
              path='clusters/:clusterId/cancel-customer-assignement'
              element={<UnassignClusterFromCustomer />}
            />

            <Route path='activities' element={<Activities />} />
            <Route
              path='activities/create-activity'
              element={<CreateActivity />}
            />
            <Route path='activities/:id' element={<EditActivity />} />
            <Route path='activities/:id/view' element={<ActionLogs />} />
            <Route path='events/*' element={<Events />} />
            <Route path='configuration' element={<Configuration />} />
            <Route path='packages-history' element={<PackageHistory />} />
            <Route path='packages-history/:id' element={<PackageDetails />} />
            <Route
              path='packages-history/:packageId/videos'
              element={<VideoList />}
            />
            <Route path='customers' element={<CustomerList />} />
            <Route path='customers/add-customer' element={<AddCustomer />} />
            <Route path='customers/:id' element={<EditCustomer />} />
            <Route path='customers/:id/courier-codes' element={<CustomerCourierCodes />} />
            <Route path='customers/:id/new-courier' element={<CustomerAddCourierCode />} />
            <Route
              path='customers/:id/assign-lockers'
              element={<CustomerLockers />}
            />
            <Route
              path='customers/:customerId/billing-data'
              element={<CustomerBilling />}
            />
            <Route path='reports' element={<Reports />} />
            <Route path='reports/fleet-load' element={<FleetLoadReport />} />
            <Route
              path='reports/fleet-load/:lockerCode'
              element={<LockerLoadReport />}
            />
            <Route
              path='reports/clusters-load'
              element={<ClustersLoadReport />}
            />
            <Route
              path='reports/clusters-load/:clusterId'
              element={<ClusterLoadReport />}
            />
            <Route
              path='reports/clusters-load/:clusterId/:lockerCode'
              element={<LockerLoadReport />}
            />
            <Route path='reports/uptime' element={<FleetUptimeReport />} />
            <Route
              path='reports/package-time-in-locker'
              element={<PackageTimeAtLockerReport />}
            />
            <Route
              path='reports/drawers-rental'
              element={<DrawersRentalReport />}
            />
            <Route path='account-settings' element={<MyAccount />} />
            <Route path='cctv' element={<CCTVSearch />} />
            <Route path='dashboard' element={<Dashboard />} />
            <Route path='dashboard/events' element={<DashboardEvents />} />
            <Route
              path='dashboard/locker-incidents'
              element={<DashboardLockerIncidents />}
            />
            <Route
              path='dashboard/drawer-incidents'
              element={<DashboardDrawerIncidents />}
            />
            <Route
              path='dashboard/package-incidents'
              element={<DashboardPackageIncidents />}
            />
            <Route
              path='dashboard/customers'
              element={<DashboardCustomersDetails />}
            />
            <Route
              path='dashboard/fleet-load'
              element={<DashboardFleetLoadTrends />}
            />
            <Route
              path='dashboard/uptime'
              element={<DashboardFleetUptimeTrends />}
            />
            <Route
              path='dashboard/clusters-load'
              element={<DashboardClustersLoadTrends />}
            />
            <Route
              path='dashboard/low-battery-lockers'
              element={<LowBatteryLockerList />}
            />
            <Route path='*' element={<PageNotFound />} />
          </Route>
        </Routes>
      </React.Suspense>
    </div>
  );
}
