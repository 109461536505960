import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

export interface FormFieldInputProps {
  label: string;
  isRequired?: boolean;
  autoFocus?: boolean;
  name: string;
  value: string | number;
  type?: string;
  hasValidation?: boolean;
  errorMsg?: string;
  inputWidth?: number;
  labelWidth?: number;
  htmlFor?: string | undefined;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function FormFieldInput({
  label,
  isRequired,
  autoFocus = false,
  name: inputName,
  value,
  type = 'text',
  hasValidation = false,
  errorMsg = '',
  inputWidth = 320,
  labelWidth = 100,
  htmlFor = undefined,
  onChange,
  disabled,
}: FormFieldInputProps) {
  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      spacing={{ xs: 1 }}
      sx={{ mb: 2 }}
      alignItems='baseline'
    >
      <InputLabel sx={{ mb: 1, width: labelWidth }} htmlFor={htmlFor}>
        {label}
        {isRequired && (
          <Typography sx={{ display: 'inline', color: 'red' }}>*</Typography>
        )}
      </InputLabel>
      <TextField
        id={htmlFor}
        disabled={disabled}
        type={type}
        name={inputName}
        value={value}
        autoFocus={autoFocus}
        error={hasValidation}
        helperText={errorMsg}
        onChange={onChange}
        sx={{ width: inputWidth }}
      />
    </Stack>
  );
}
