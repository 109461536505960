import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DateIntervalFilter from '../common/DateIntervalFilter';

export default function CCTVSearchForm() {
  const { t } = useTranslation();
  const startDate = new Date();
  startDate.setHours(startDate.getHours() - 2);

  return (
    
    <div>
      <Typography variant='h4' sx={{ mb: 3 }}>
        {t('cctv_search')}
      </Typography>
      <Box sx={{ mb: 2 }}>
      <DateIntervalFilter
        startDate={startDate}
        endDate={new Date()}
        onStartDateChange={(e) => console.log(e) } onEndDateChange={(e) => console.log(e) } />
        </Box>
    </div>
  );
}
