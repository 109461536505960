import * as React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  useGetCustomerById,
  addCourierCodeToCustomer,
} from '../../services/requests';

import LoadingComponent from '../common/LoadingComponent';
import { getErrorMessage } from '../../utils';
import ErrorDialog from '../common/ErrorDialog';
import GoToPreviousPageButton from '../common/GoToPreviousPageButton';
import CourierCodeForm from './CourierCodeForm';

export default function CustomerAddCourierCode() {
  const { id } = useParams();
  const { state } = useLocation();
  const { data, isLoading, isSuccess, isError, error } = useGetCustomerById(
    id as string
  );
  const [courier, setCourier] = useState({
    customerId: '',
    courierCode: '',
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (data) {
      setCourier({ ...courier, customerId: data.customerId });
      console.log(courier);
    }
  }, [data]);

  const getError = () => {
    let currentError = '';
    const errorList = {
      getCustomerDataErrorMsg: t('get_data_error'),
    };
    if (isError) {
      currentError = getErrorMessage(
        error as AxiosError,
        errorList.getCustomerDataErrorMsg
      );
    }
    if (currentError) {
      return <ErrorDialog status message={currentError} />;
    }
    return null;
  };

  return (
    <div>
      {isLoading && <LoadingComponent />}
      {getError()}
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <GoToPreviousPageButton />
      </Box>
      <Typography
        variant='h4'
        sx={{ mb: 3, display: 'flex', justifyContent: 'flex-start' }}
      >
        {t('add_courier')}: {state.companyName}
      </Typography>
      {isSuccess && (
        <CourierCodeForm mutationFn={addCourierCodeToCustomer} state={state} courier={courier} />
      )}
    </div>
  );
}
