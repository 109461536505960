/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Hidden from '@mui/material/Hidden';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Autocomplete,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';

import isLength from 'validator/lib/isLength';
import isEmpty from 'validator/lib/isEmpty';

import { QueryParams } from '@/src/types/general.types';
import { Locker } from '@/src/types/locker.types';
import {CourierCode} from '../../types/customer.types';
import { useLockers } from '../../services/requests';

import {
  MIN_COURIER_CODE_LENGTH,
  MAX_COURIER_CODE_LENGTH
} from '../../constants';

import { getErrorMessage } from '../../utils';
import LoadingComponent from '../common/LoadingComponent';
import ErrorDialog from '../common/ErrorDialog';
import FormFieldInput from '../common/FormFieldInput';


export interface CourierFormProps {
  mutationFn: (data: CourierCode) => Promise<AxiosResponse>;
  courier: CourierCode;
  pageTitle?: string;
  state?: any;
}

export default function CourierCodeForm({
  mutationFn,
  courier,
  pageTitle,
  state
}: CourierFormProps) {
  const navigate = useNavigate();
  const { id } = useParams();
  const courierMutation = useMutation((data: CourierCode) => mutationFn(data), {
    onSuccess: () => navigate(`/customers/${id}/courier-codes`, { state: { companyName: state.companyName } })
  });
  const [courierData, setCourierData] = useState(courier);
  const [errors, setErrors] = useState({
    courierCodeError: '',
    lockerIdError: '',
  });

  const { t, i18n } = useTranslation();

  const {
    data: lockersData,
    isLoading: loadingLockers,
    error: lockersError,
    isError: isLockerError,
    refetch,
  } = useLockers({ page: 1, pageSize: 100 } as unknown as QueryParams);

  useEffect(() => {
    if (courier) {
      setCourierData({ ...courier });
      console.log(courier);
    }
  }, [courier]);


  useEffect(() => {
    setErrors({
      courierCodeError: '',
      lockerIdError: '',
    });
  }, [i18n.language]);



  const validateCourier = (data: CourierCode): boolean => {
    const isCourierCodeValid = isLength(
      data.courierCode as string,
      {
        min: MIN_COURIER_CODE_LENGTH,
        max: MAX_COURIER_CODE_LENGTH,
      }
    );

    return (
      isCourierCodeValid
    );
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validateCourier(courierData)) {
      if (!id) {
        delete courierData.customerId;
      }

      courierMutation.mutate({
        ...courierData,
      });
    }
  };

  const handleOnChangeFields = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string | number | null>
      
  ) => {
    setCourierData({
      ...courierData,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      [`${e.target.name}Error`]: '',
    });
  };

  const getError = () => {
    let currentError = '';
    const errorList = {
      editErrorMsg: t('save_data_err'),
      createErrorMsg: t('save_data_err'),
    };
    if (courierMutation.isError) {
      const { error: createUserError } = courierMutation;

      currentError = getErrorMessage(
        createUserError as AxiosError,
        errorList.createErrorMsg
      );
    }
    if (currentError) {
      return <ErrorDialog status message={currentError} />;
    }
    return null;
  };

  return (
    <Box>
      {(courierMutation.isLoading || loadingLockers) && (
        <LoadingComponent />
      )}
      {getError()}
      {lockersData && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <form onSubmit={handleSubmit}>
            {pageTitle && (
              <Typography
                variant='h4'
                sx={{ mb: 3, display: 'flex', justifyContent: 'flex-start' }}
              >
                {t(pageTitle)}
              </Typography>
            )}

            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1 }}
              sx={{ mb: 2 }}
              alignItems='baseline'
            >
              <InputLabel
                sx={{ mb: 1, width: 200 }}
                id='locker_id'
              >
                {`${t('locker_code')}:`}
                <Typography sx={{ display: 'inline', color: 'red' }}>
                  *
                </Typography>
              </InputLabel>
              <Select
                name='lockerCode'
                value={null}
                onChange={(e) => handleOnChangeFields(e)}
                sx={{ width: 320 }}
                labelId='locker_id'
                disabled={!!id}
              >
                {lockersData.map((locker:Locker) => (
                  <MenuItem key={locker.lockerCode} value={locker.lockerCode}>
                    {locker.lockerCode}
                  </MenuItem>
                ))}
            
              </Select>
            </Stack>
            <FormFieldInput
              label={`${t('courier_code')}:`}
              isRequired
              name='courierCode'
              htmlFor='courierCode'
              value={courierData.courierCode || ''}
              hasValidation={!!errors.courierCodeError}
              errorMsg={errors.courierCodeError}
              onChange={handleOnChangeFields}
              labelWidth={200}
            />

            <Stack
              direction='row'
              spacing={{ xs: 1 }}
              sx={{ mb: 2 }}
              alignItems='flex-start'
            >
              <Hidden smDown>
                <InputLabel sx={{ mb: 1, width: 200 }}> </InputLabel>
              </Hidden>
              <Typography sx={{ display: 'inline', color: 'red' }}>
                *
              </Typography>
              {t('required_field')}
            </Stack>
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                type='submit'
                variant='contained'
                sx={{ textTransform: 'capitalize' }}
                size='medium'
              >
                {t('save')}
              </Button>
            </Box>
          </form>
        </Box>
      )}
    </Box>
  );
}

