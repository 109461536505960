import React from 'react';
import CCTVSearchForm from './CCTVSearchForm';

export default function CCTVSearch() {

  return (
    <div>
      <CCTVSearchForm />
    </div>
  );
}

